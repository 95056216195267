import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, animateScroll as scroll } from "react-scroll";
import img1 from "../assets/images/logonavbar.svg";
import icon from "../assets/images/iconset.png";
const Nav = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  return (
    <>
      <div className="bg-[#FFFFFF] py-5 xl:px-24 md:px-6 px-4 flex justify-between w-full">
        <div className="relative">
          <img
            src={img1}
            alt=""
            className="md:w-[102.39px] md:h-[48px] w-[70px] h-[48px]"
          />
        </div>

        <div className="md:hidden">
          {/* Mobile Menu Icon or Close Icon */}
          {mobileMenuOpen ? (
            <FaTimes
              onClick={toggleMobileMenu}
              className="text-[#22205F] text-2xl cursor-pointer"
            />
          ) : (
            <div
              className="w-[48px] h-[48px] flex items-center justify-center rounded-full border-none bg-[#22205F] cursor-pointer"
              onClick={toggleMobileMenu}
            >
              <img src={icon} alt="menu" />
            </div>
            // <FaBars
            //   onClick={toggleMobileMenu}
            //   className="text-[#22205F] text-2xl cursor-pointer"
            // />
          )}

          {/* Mobile Menu Dropdown */}
          {mobileMenuOpen && (
            <ul className="bg-[#22205F] absolute top-20 left-0 right-0 rounded-md flex flex-col items-center z-10 ">
              <Link
                to="services"
                spy={true}
                smooth={true}
                duration={1000}
                data-te-nav-link-ref
                className="mx-2 text-[#FFFFFF] py-2 w-full text-center font-normal md:text-[16px] text-sm font-inter leading-[20px] cursor-pointer"
              >
                Our Services
              </Link>

              <Link
                to="aboutUs"
                spy={true}
                smooth={true}
                duration={2000}
                data-te-nav-link-ref
                className="mx-2 text-[#FFFFFF] py-2 w-full text-center font-normal md:text-[16px] text-sm font-inter leading-[20px] cursor-pointer"
              >
                About Us
              </Link>

              <Link
                to="contact"
                spy={true}
                smooth={true}
                duration={2000}
                data-te-nav-link-ref
                className="mx-2 text-[#FFFFFF] py-2 w-full text-center font-normal md:text-[16px] text-sm font-inter leading-[20px] cursor-pointer"
              >
                Contact
              </Link>
            </ul>
          )}
        </div>
        <div className="bg-[#22205F] md:w-[342px] md:h-[50px]  w-[300px] h-[50px] rounded-full md:block hidden">
          <ul
            className="flex items-center px-6 text-center pt-3"
            data-te-navbar-nav-ref
          >
            <Link
              to="services"
              spy={true}
              smooth={true}
              duration={1000}
              data-te-nav-link-ref
              className="mx-2 text-[#FFFFFF] font-normal md:text-[16px] text-sm font-inter leading-[20px] cursor-pointer"
            >
              Our Services
            </Link>

            <Link
              to="aboutUs"
              spy={true}
              smooth={true}
              duration={2000}
              data-te-nav-link-ref
              className="mx-2 text-[#FFFFFF] font-normal md:text-[16px] text-sm font-inter leading-[20px] cursor-pointer"
            >
              About Us
            </Link>

            <Link
              to="contact"
              spy={true}
              smooth={true}
              duration={2000}
              data-te-nav-link-ref
              className="mx-2 text-[#FFFFFF] font-normal md:text-[16px] text-sm font-inter leading-[20px] cursor-pointer"
            >
              Contact
            </Link>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Nav;
