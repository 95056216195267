import React from "react";
import img from "../assets/images/nigeria.png";
import logo from "../assets/images/logomobile.png";
import { IoMailUnread } from "react-icons/io5";
import { TiSocialFacebook } from "react-icons/ti";
import { FaInstagram } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  fname: Yup.string().required("Full Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  message: Yup.string().required("Message is required"),
});

const Contact = () => {
  const handleSubmit = (values, { setSubmitting }) => {
    console.log("Form values:", values);
    setSubmitting(false);
  };
  return (
    <div
      className="bg-[#22205F] w-full  lg:px-6 md:px-6 xl:px-24 py-10 px-8"
      id="contact"
    >
      <div className="grid md:grid-cols-2 grid-cols-1 gap-10">
        <div className="bg-[#FFFFFF] w-[100%] rounded-[20px] border border-[#F2F2F2] px-6 py-6">
          <div>
            <h3 className="text-[24px] font-bold font-inter text-[#1A1A1A]">
              Contact Us
            </h3>
          </div>

          <Formik
            initialValues={{
              fname: "",
              email: "",
              message: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form action="">
              <div className="py-1">
                <label
                  htmlFor="fname"
                  className="text-[#010101] font-medium text-[14px] font-inter"
                >
                  Full Name
                  <ErrorMessage
                    name="fname"
                    component={() => <span className="text-red-500">*</span>}
                  />
                </label>
                <Field
                  type="text"
                  id="fname"
                  name="fname"
                  className="w-full h-[38px] border border-[#E9EBED] bg-[#FCFCFD] pl-2 focus:outline-none mt-2 py-5 rounded-[4px]"
                />
                <ErrorMessage
                  name="fname"
                  component="div"
                  className="text-red-500 text-[12px]"
                />
              </div>
              <div className="py-1">
                <label
                  htmlFor="email"
                  className="text-[#010101] font-medium text-[14px] font-inter"
                >
                  Email{" "}
                  <ErrorMessage
                    name="email"
                    component={() => <span className="text-red-500">*</span>}
                  />
                </label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  className="w-full h-[38px] border border-[#E9EBED] bg-[#FCFCFD] pl-2 focus:outline-none mt-2 py-5 rounded-[4px]"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-[12px]"
                />
              </div>
              <div className="py-3">
                <label
                  htmlFor="email"
                  className="text-[#010101] font-medium text-[14px] font-inter"
                >
                  Message{" "}
                  <ErrorMessage
                    name="message"
                    component={() => <span className="text-red-500">*</span>}
                  />
                </label>{" "}
                <br />
                <Field
                  as="textarea"
                  id="message"
                  name="message"
                  cols={30}
                  className="w-full border border-[#E9EBED] bg-[#FCFCFD] pl-2 focus:outline-none mt-2 py-5 rounded-[4px]"
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  className="text-red-500 text-[12px]"
                />
              </div>

              <button
                type="submit"
                className="mt-5 w-full md:h-[56px] h-[45px] bg-[#22205F] rounded-[32px] text-[#FCFCFD] font-inter font-medium text-[16px]"
              >
                Send Message
              </button>
            </Form>
          </Formik>
        </div>
        <div className="relative mt-6 w-[100%]">
          <img src={logo} alt="logo" />
          <div className="pt-5">
            <p className="font-bold text-[16px] text-[#FFFFFF] font-inter">
              Offices
            </p>
          </div>
          <div className="py-3">
            <p className="text-[#FFFFFF] font-normal text-[16px]  font-inter">
              Shop 85 Amosun <br /> Shopping complex itoku <br /> Abeokuta
              Ogunstate
            </p>
          </div>
          <div>
            <p className="font-bold text-[16px] text-[#FFFFFF] font-inter py-6">
              For Quick Inquiries
            </p>
            {/* 
            <div className="flex py-3 items-center gap-2">
              <IoMailUnread size={28} color="#ffff" />
              <a href="mailto:wyntlimited@gmail.com">
                <span className="text-[#ffff] font-normal text-[16px]  font-inter">
                  wyntlimited@gmail.com
                </span>
              </a>
            </div> */}
            <div className="flex ">
              <img src={img} alt="" />
              <span className="text-[#ffff] font-normal text-[16px]  font-inter">
                +234 9019302697
              </span>
            </div>
            <div className="flex mt-2 ">
              <img src={img} alt="" />
              <span className="text-[#ffff] font-normal text-[16px]  font-inter">
                +234 9019302697
              </span>
            </div>

            <div className="flex gap-3 items-center my-7">
              <div className="flex items-center justify-center w-[50px] h-[50px] bg-[#fff] rounded-full">
                <TiSocialFacebook color="#0A142F" />
              </div>
              <div className="flex items-center justify-center w-[50px] h-[50px] bg-[#fff] rounded-full">
                <FaInstagram color="#0A142F" />
              </div>
              <div className="flex items-center justify-center w-[50px] h-[50px] bg-[#fff] rounded-full">
                <FaTwitter color="#0A142F" />
              </div>
            </div>
            <p className="text-[#FFFFFF] font-inter font-normal text-[14px]">
              © 2024 Wynt. All rights reserved.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
