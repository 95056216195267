import React from "react";
import img from "../assets/images/image4.svg";
import logo from "../assets/images/Logo.png";
const Hero = () => {
  return (
    <div
      className=" w-full md:px-4 lg:px-6 xl:px-24 px-4 py-10"
      id="aboutUs"
    >
      <div className="grid md:grid-cols-2 gap-4  grid-cols-1">
        <div className="w-[100%] bg-[#22205F] rounded-[20px] px-6 py-6">
          <div>
            <img
              src={logo}
              alt=""
              className="xl:w-[150px] xl:h-[150px] w-[70px] h-[70px] md:w-[70px] md:h-[70px] "
            />
          </div>

          <div>
            <p className="font-semibold text-[24px] font-inter pt-10 text-[#fff]">
              Our Purpose
            </p>
            <p className="font-normal md:text-[14px] lg:text-[17px]   font-inter leading-[30px] text-[#fff] pt-4">
              WYNT Ltd. is a company inspired by the colossal impact of
              Technology across all spheres of life and human endeavours. Ours
              is an organisation committed to the utilisation of technology and
              its advancements by proffering impactful soft and technical
              solutions that make for the ease of life and businesses.
            </p>
          </div>
        </div>

        <div className="w-[100%]">
          <img src={img} alt="team"  />
        </div>
      </div>
    </div>
  );
};

export default Hero;
