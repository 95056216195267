import React from "react";
import img1 from "../assets/images/Image1.png";
import img2 from "../assets/images/image2.png";
import img3 from "../assets/images/image3.png";
import logo1 from "../assets/images/logo1.svg";
import logo2 from "../assets/images/logo2.svg";
import logo3 from "../assets/images/logo3.svg";
import { BsChevronRight } from "react-icons/bs";

const Service = () => {
  return (
    <div
      className="bg-[#FCFCFD] w-full px-8 md:px-6 lg:px-6 xl:px-24 py-8"
      id="services"
    >
      <h2 className="font-vastego text-[#010101] xl:text-[55px] lg:text-[55px] md:text-[50px] text-[35px] md:text-center">
        Our Services
      </h2>

      <div className="grid grid-rows-3 gap-8 my-6">
        <div className="grid md:grid-cols-2 grid-cols-1 md:gap-20 md:py-8 bg-[#F2F2F2] gap-10 px-4 md:px-8 w-full rounded-lg border border-[#F2F2F2] ">
          <div className="my-auto">
            <div>
              <img
                src={logo1}
                alt=""
                className="xl:w-[267.32px] xl:h-[64px] lg:w-[267.32px] lg:h-[64px] md:w-[267.32px] md:h-[64px] w-[200px] h-[60px] my-2"
              />
            </div>
            <p className="text-[16px] xl:w-[500px] lg:w-[500px] md:w-[400px] leading-[25px] font-inter text-[#333333] py-3 font-normal">
              Step into Coastlane Travel where dreams soar and adventure knows
              no limits. Choose from our carefully curated packages, fulfilling
              education, family time, career goals, or relaxation. Your dream
              journey awaits!
            </p>
            <div className="relative">
              <button className="w-[229px] h-[50px] bg-[#22205F] rounded-[32px] mt-3 font-inter font-medium text-[16px] text-[#FCFCFD]">
                <a href="https://coastlanetravel.com/" target="_blank">
                  {" "}
                  Visit Website
                </a>
              </button>
              <BsChevronRight
                className=" hidden md:block absolute  md:left-[11rem]  md:top-[29px]"
                size={16}
                color="#fff"
              />
            </div>
          </div>
          <div className="relative xl:top-7 lg:top-8 md:top-10 md:left-10">
            <img src={img1} alt="" className="xl:w-[497px] xl:h-[417px]" />
          </div>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 bg-[#E8FCFC] gap-10 px-4 md:px-8 w-full rounded-lg border border-[#E8FCFC] ">
          <div className="my-auto">
            <div>
              <img
                src={logo3}
                alt=""
                className="xl:w-[267.32px] xl:h-[64px] lg:w-[267.32px] lg:h-[64px] md:w-[267.32px] md:h-[64px] w-[200px] h-[60px] relative left-[-20px]"
              />
            </div>
            <p className="text-[15px] md:text-[16px] xl:w-[500px] lg:w-[500px] md:w-[400px] leading-[25px] font-inter text-[#333333] py-3 font-normal">
              Discover top-notch, locally crafted Upholsteries, Furniture for
              Homes & Offices, and quality Aluminum Doors – all in one
              convenient online emporium. Elevate your space with our
              handcrafted excellence.
            </p>
            <div className="relative">
              <button className="w-[229px] h-[50px] bg-[#22205F] rounded-[32px] mt-3 font-inter font-medium text-[16px] text-[#FCFCFD]">
                <a href="https://furstore.com.ng/" target="_blank">
                  {" "}
                  Visit Website
                </a>
              </button>
              <BsChevronRight
                className="hidden md:block absolute  md:left-[11rem]  md:top-[29px]"
                size={16}
                color="#fff"
              />
            </div>
          </div>
          <div className="relative xl:top-14 lg:top-14 md:top-24  xl:left-14 lg:left-14 md:left-20">
            <img
              src={img2}
              alt=""
              className="xl:w-[400px] md:w-[250px] lg:w-[370px]"
            />
          </div>
        </div>
       
        <div className="grid md:grid-cols-2 grid-cols-1  bg-[#FDEBE7] gap-10 px-4 md:px-8 w-full rounded-lg border border-[#FDEBE7] ">
          <div className="my-auto">
            <div>
              <img
                src={logo2}
                alt=""
                className="xl:w-[267.32px] xl:h-[64px] lg:w-[267.32px] lg:h-[64px] md:w-[267.32px] md:h-[64px] w-[200px] h-[60px] my-2"
              />
            </div>
            <p className="text-[16px] xl:w-[500px] lg:w-[500px] md:w-[400px] leading-[25px] font-inter text-[#333333] py-3 font-normal">
              Explore our premier online Auto Shop featuring Hummers and Luxury
              Buses. Discover pristine vehicles for sale or customize repairs to
              perfection. Seamless doorstep delivery, elevating convenience for
              you.
            </p>
            <div className="relative">
              <button className="w-[229px] h-[50px] bg-[#22205F] rounded-[32px] mt-3 font-inter font-medium text-[16px] text-[#FCFCFD]">
                <a href="https://coastlanetravel.com/" target="_blank">
                  {" "}
                  Visit Website
                </a>
              </button>
              <BsChevronRight
                className=" hidden md:block absolute  md:left-[11rem]  md:top-[29px]"
                size={16}
                color="#fff"
              />
            </div>
          </div>
          <div className="relative xl:top-7 md:top-20 md:left-8">
            <img src={img3} alt="" className="xl:w-[497px] xl:h-[417px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
