import React from "react";
import img1 from "../assets/images/img1.png";
import img2 from "../assets/images/img2.png";
import img3 from "../assets/images/Vector.png";
const Feature = () => {
  return (
    <div className="w-full  px-4 lg:px-6 md:px-4 xl:px-24 py-10">
      <div className="grid md:grid-cols-2   gap-10 grid-cols-1">
        <div className="w-[100%] xl:p-10 p-5 md:p-5 bg-[#ECECF9] border-none rounded-xl">
          <div>
            <p className="font-semibold xl:text-[24px] lg:text-[24px] md:text-[20px] text-[24px]  font-inter text-[#010101] md:py-2 lg:py-3">
              Our Values
            </p>
            <p className="font-normal xl:w-[520px] xl:text-[17px]  text-[17px] md:text-[16px] lg:text-[17px] leading-[30px] font-inter text-[#333333]">
              We are a diverse team of vibrant and ambitious like minded
              individuals with deep-rooted backgrounds and knowledge of
              different fields of study and business undertaking the challenge
              of providing expert services and solutions in areas of keen
              interest.
            </p>
          </div>
          <div className="relative">
            <div>
              <img src={img3} alt="" className="w-[100%] h-[300px]" />
            </div>
            <div className="flex justify-center items-center">
              <img
                src={img1}
                alt=""
                className="w-[120px] h-[120px]  absolute top-16"
              />
            </div>
          </div>
        </div>

        <div className="w-[100%] xl:p-10 md:p-5 p-5 bg-[#E8F9FD] border-none rounded-xl">
          <div>
            <p className="font-semibold xl:text-[24px] lg:text-[24px] md:text-[20px] text-[24px]  font-inter text-[#010101] md:py-1 lg:py-3">
              Our Goal
            </p>
            <p className="font-normal xl:w-[520px] leading-[30px] xl:text-[17px]  text-[17px] md:text-[16px] lg:text-[17px] font-inter text-[#333333]">
              At WYNT LTD. ensuring the ease of life, and simplicity in
              performing business and professional operations is our utmost
              priority. Our products and services are tailored to this ultimate
              objective, as we pride ourselves in driving creativity and pushing
              beyond limits to bolster initiatives.
            </p>
          </div>
          <div>
            <div className="relative">
              <div>
                
                <img src={img3} alt="" className="w-[100%] h-[300px]" />
              </div>
              <div className="flex justify-center items-center">
                <img
                  src={img2}
                  alt=""
                  className="w-[120px] h-[120px]  absolute top-16  "
                />
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
