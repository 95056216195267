import "./App.css";

import Contact from "./components/Contact";
import Feature from "./components/Feature";

import Header from "./components/Header";
import Hero from "./components/Hero";
import Nav from "./components/Nav";
import Service from "./components/Service";
import Experience from "./components/experience";

function App() {
  return (
    <div className="bg-[#fcfcfc] overflow-hidden ">
      <Nav />
      <Header />
      <Experience />
      <Service />
      <Hero />
      <Feature />

      <Contact />
    </div>
  );
}

export default App;
