import React from "react";

const Experience = () => {
  return (
    <>
    <section className="my-10">
    <div className="block md:flex justify-center gap-36 items-center relative  z-0 ">
        <div className="flex items-center flex-col relative">
          <p className="font-vastego text-[32px] md:text-[48px] font-semibold text-[#010101]">
            5 Years
          </p>
          <p className="font-inter text-[16px] md:text-[18px] font-medium text-[#4D4D4D]">
            In Business
          </p>
          <div className="absolute md:w-[200px] md:h-[16px] w-[135px] h-[10px] bg-[#A2E5F6] md:top-9 top-7 -z-10"></div>
        </div>
        <div className="flex items-center flex-col relative mt-6 md:mt-0">
          <p className="font-vastego text-[32px] md:text-[48px] font-semibold text-[#010101]">
            100+
          </p>
          <span className="font-inter text-[16px] md:text-[18px] font-medium text-[#4D4D4D]">
            Partners
          </span>
          <div className="absolute md:w-[120px] md:h-[16px] w-[90px] h-[10px] bg-[#A2E5F6] md:top-9 top-7 -z-10"></div>
        </div>
        <div className="flex items-center flex-col relative mt-6 md:mt-0">
          <p className="font-vastego text-[32px] md:text-[48px] font-semibold text-[#010101]">
            800+
          </p>
          <span className="font-inter text-[16px] md:text-[18px] font-medium text-[#4D4D4D]">
            Customer
          </span>
          <div className="absolute md:w-[120px] md:h-[16px] w-[90px] h-[10px] bg-[#A2E5F6] md:top-9 top-7 -z-10"></div>
        </div>
      </div>
    </section>
   
    </>
  );
};

export default Experience;
