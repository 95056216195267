import React from "react";
import { BsChevronRight } from "react-icons/bs";
import img1 from "../assets/images/slider3.svg";
import img2 from "../assets/images/slider2.svg";
import img3 from "../assets/images/slider1.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Header = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };
  return (
    <>
      <section className="xl:px-24 md:px-6 bg-[#FCFCFD] px-4 py-6 w-full md:block hidden">
        <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-10 ">
          <div className="w-[100%]">
            <h1 className="text-[#010101] font-vastego font-extrabold text-[30px] lg:text-[50px] md:text-[30px] lg:leading-[80px] ">
              Elevating <br /> Experiences <br />
              Through Expertise
            </h1>
            <p className=" xl:w-[520px] font-inter text-[16px] font-normal leading-[26px] text-[#666666] pt-3">
              Your Destination for Technology, Logistics, Finance, Travel, and
              Management Solutions. Unwavering Commitment, Unparalleled Service.
            </p>

            <div className="relative">
              <button className="w-[229px] h-[50px] bg-[#22205F] rounded-[32px] mt-8 font-inter font-medium text-[16px] text-[#FFFFFF]">
                <a href="" target="_blank">
                  Our Services
                </a>
              </button>
              <BsChevronRight
                className="hidden md:block absolute  md:left-[11rem]  md:top-[50px]"
                size={16}
                color="#FFFFFF"
              />
            </div>
          </div>
          <div className="w-[100%]">
            <Slider {...settings}>
              <div className="relative">
                <img src={img1} alt="" className="w-[646px] h-[500px]" />
              </div>
              <div className="relative">
                <img src={img2} alt="" className="w-[646px] h-[500px]" />
              </div>
              <div className="relative">
                <img src={img3} alt="" className="w-[646px] h-[500px]" />
              </div>
            </Slider>
          </div>
        </div>
      </section>

      <section className="xl:px-24 md:px-6 px-4  py-14 w-full md:hidden block">
        <div className="grid md:grid-cols-2 gap-5 grid-cols-1 items-center">
          <div className="w-[100%]">
            <Slider {...settings}>
              <div className="relative">
                <img src={img1} alt="" />
              </div>
              <div className="relative">
                <img src={img2} alt="" />
              </div>
              <div className="relative">
                <img src={img3} alt="" />
              </div>
            </Slider>
          </div>
          <div className="w-[100%]">
            <h1 className="text-[#010101] font-vastego font-extrabold text-[35px] lg:text-[50px] md:text-[30px] lg:leading-[80px] ">
              Elevating <br /> Experiences <br />
              Through Expertise
            </h1>
            <p className=" xl:w-[520px] font-inter text-[16px] font-normal leading-[26px] text-[#666666] pt-3">
              Your Destination for Technology, Logistics, Finance, Travel, and
              Management Solutions. Unwavering Commitment, Unparalleled Service.
            </p>

            <div className="relative">
              <button className="w-[229px] h-[50px] bg-[#22205F] rounded-[32px] mt-8 font-inter font-medium text-[16px] text-[#FFFFFF]">
                <a href="" target="_blank">
                  Our Services
                </a>
              </button>
              <BsChevronRight
                className="hidden md:block absolute  md:left-[11rem]  md:top-[50px]"
                size={16}
                color="#FFFFFF"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
